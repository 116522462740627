import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import {
  AllTimeTimeFilter,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import { OldTimeFilter } from 'src/components/ReportSaver/models';
import { fetchTotalAutomationStatsAPI } from 'src/lib/api/reports';
import { RootModel } from 'src/store/models';

export interface State {
  isFetching: boolean;
  timeFilter: TimeFilter;
  total: {
    delivered: number;
    clicked: number;
    revenue: number;
    orders: number;
  };
  abandonedCart: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  welcomeNotifications: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  browseAbandonment: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  shippingNotifications: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  backInStock: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  priceDrop: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
}

const initialState: State = {
  isFetching: true,
  timeFilter: AllTimeTimeFilter,
  total: {
    delivered: 0,
    clicked: 0,
    revenue: 0,
    orders: 0,
  },
  abandonedCart: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  welcomeNotifications: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  browseAbandonment: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  shippingNotifications: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  backInStock: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  priceDrop: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
};

const automationStats = createModel<RootModel>()({
  state: initialState,
  effects: dispatch => ({
    async fetchTotalAutomationStats(
      // TODO:aftermigration - use the new time filter type only
      { timeFilter }: { timeFilter: TimeFilter | OldTimeFilter },
      rootState,
    ) {
      const t = await getT(Router.locale, 'toasts');
      this.setState({ isFetching: true });

      const { data, error } = await fetchTotalAutomationStatsAPI(
        timeFilter,
        rootState.settings.attributionWindow,
      );

      if (error) {
        dispatch.saveToast.showError(t('Error fetching statistics'));
        return;
      }

      this.setState({
        ...initialState,
        ...data,
        isFetching: false,
        timeFilter,
      });
    },
  }),
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default automationStats;
