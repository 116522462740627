import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { PlanChange, changePlanAPI } from 'src/lib/api/pricing';
import { handleRedirect } from 'src/lib/utils';

export enum PromotionTypes {
  AbandonedCart = 'abandoned-cart',
  Shipping = 'shipping',
  HeroImage = 'hero-image',
  FlashSale = 'flash-sale',
  SmartDelivery = 'smart-delivery',
  Segmentation = 'segmentation',
  BrowserAbandonment = 'browser-abandonment',
}

interface State {
  isOpen: boolean;
  popupType: PromotionTypes;
  isPlanChangeInProgress: boolean;
}

const initialState = () => ({
  isOpen: false,
  popupType: null,
  isPlanChangeInProgress: false,
});

const promotionPopups = createModel()({
  state: initialState(),

  reducers: {
    show(state: State, payload: PromotionTypes): State {
      return {
        ...state,
        isOpen: true,
        popupType: payload,
      };
    },

    hide(state: State): State {
      return {
        ...state,
        ...initialState(),
      };
    },

    setIsPlanChangeInProgress(state: State, payload): State {
      return {
        ...state,
        isPlanChangeInProgress: payload,
      };
    },
  },
  effects: dispatch => ({
    async changePlan(payload: PlanChange) {
      const t = await getT(Router.locale, 'toasts');

      const res = await changePlanAPI(payload);

      if (!res.error) {
        this.hide();
        handleRedirect(res.data.confirmation_url);
      } else {
        dispatch.saveToast.showError(t('Error fetching pricing'));
      }
    },
  }),
});

export default promotionPopups;
