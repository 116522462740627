import { useEffect } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import setLanguage from 'next-translate/setLanguage';
import SessionValidation from 'src/components/SessionValidation';
import { GlobalStateInitializer } from 'src/components/GlobalStateInitialiser';
import { isLocalStorageAvailable } from 'src/lib/utils';
import { AppProvider, Spinner } from '@shopify/polaris';
import { GlobalModals } from './GlobalModals';
import { PolarisLinkWrapper } from './PolarisLinkWrapper';

export const App = ({
  Component,
  pageProps,
  isAppDisabled,
  isPageLoading,
  translations,
}) => {
  const {
    actions: { refetchUser },
  } = useGlobalState('user');

  const {
    actions: { shopifyLogin },
  } = useGlobalState('user');

  const handleLanguage = async () => {
    if (isLocalStorageAvailable()) {
      const language = localStorage.getItem('NEXT_LOCALE') || 'en';
      await setLanguage(language);
    }
  };

  useEffect(() => {
    handleLanguage();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    //  Checking host param for triggering installation flow
    const hostParam = queryParams.get('host');

    if (hostParam) {
      shopifyLogin();
    } else {
      refetchUser();
    }
  }, []);

  return (
    <AppProvider i18n={translations} linkComponent={PolarisLinkWrapper}>
      {isPageLoading && (
        <div
          style={{
            position: 'fixed',
            top: '5px',
            left: '50%',
            zIndex: 999999,
          }}
        >
          <Spinner />
        </div>
      )}

      <GlobalModals isAppDisabled={isAppDisabled} />

      {isAppDisabled && <SessionValidation />}

      {!isAppDisabled && (
        <GlobalStateInitializer>
          <Component {...pageProps} />
        </GlobalStateInitializer>
      )}
    </AppProvider>
  );
};
