import { createModel } from '@rematch/core';
import { fetchBannersAPI } from 'src/lib/api/misc';
import { RootModel } from 'src/store/models';

type BannerState = {
  banners: [
    {
      buttons: Array<{
        text: string;
        handler?: string;
        action?: string;
        url?: string;
        target?: string;
      }>;
      category: string;
      class: string;
      icon: string;
      title: string;
      paths: [string];
      description: string;
    }?,
  ];
  isFetching: boolean;
  isAnimationDone: boolean;
};

const banners = createModel<RootModel>()({
  state: {
    banners: [],
    isFetching: true,
    isAnimationDone: false,
  } as BannerState,

  reducers: {
    setState(state: BannerState, payload: Partial<BannerState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: () => ({
    async fetchBanners() {
      this.setState({ isFetching: true });
      const { data, error } = await fetchBannersAPI();
      this.setState({ banners: error ? [] : data, isFetching: false });
    },
  }),
});

export default banners;
