import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import {
  createDomainAPI,
  createSenderAPI,
  createSubAccountAPI,
  fetchDomainAPI,
  fetchRedirectURLAPI,
  fetchSubAccountAPI,
  validateSenderAPI,
} from 'src/lib/api/brevo';
import { createMerchantTaskAPI } from 'src/lib/api/misc';
import { MerchantTask } from 'src/lib/constants';
import { getStorage } from 'src/lib/storage';
import { RootModel } from 'src/store/models';
import { EmailOnboardingStep, OnboardingStatus } from './constants';

export type EmailOnboardingStatus = {
  dataSyncStatus: OnboardingStatus;
  domainVerificationStatus: OnboardingStatus;
  senderVerificationStatus: OnboardingStatus;
};

const ONBOARDING_STEP_PERSIST_KEY = 'email-onboarding-step';

export const validBrevoRedirectURLs = [
  'marketing-campaign/list',
  'automation/automations',
  'contact/segment',
  'advanced/config',
] as const;

export type ValidBrevoRedirectURLs = (typeof validBrevoRedirectURLs)[number];

const totalOnboardingSteps = Object.values(EmailOnboardingStep).filter(
  // because ts enum object.values() also has the keys for some reason
  Number.isFinite,
).length;

type EmailModelState = {
  onboarding: {
    currentStep: number;
    senderEmailVerificationSent: boolean;
    isDomainSuccessfullyAdded: boolean;
  };
  subAccountId: number;
  onboardingStatus: EmailOnboardingStatus | null;
  isLoading: boolean;
  permissionURL: string;
};

type SubAccountReturnType = Pick<
  EmailModelState,
  'subAccountId' | 'onboardingStatus'
>;

const initialState: EmailModelState = {
  onboarding: {
    currentStep: EmailOnboardingStep.ABOUT_1,
    senderEmailVerificationSent: false,
    isDomainSuccessfullyAdded: false,
  },
  subAccountId: undefined,
  onboardingStatus: null,
  isLoading: false,
  permissionURL: null,
};

const email = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    goToOnboardingStep(
      payload: {
        stepBy?: number;
        jumpTo?: EmailOnboardingStep;
      },
      rootState,
    ) {
      if (typeof payload.jumpTo !== 'undefined') {
        dispatch.email.setOnboardingState({
          currentStep: payload.jumpTo % totalOnboardingSteps,
        });
        return;
      }

      if (typeof payload.stepBy !== 'undefined') {
        dispatch.email.setOnboardingState({
          currentStep:
            Math.abs(rootState.email.onboarding.currentStep + payload.stepBy) %
            totalOnboardingSteps,
        });
      }
    },

    async refetchSubAccount(): Promise<SubAccountReturnType> {
      const { data, error } = await fetchSubAccountAPI();

      let subAccount;
      if (data && !error) {
        subAccount = {
          subAccountId: data.sub_account_id,
          onboardingStatus: {
            dataSyncStatus: data.data_sync_status,
            domainVerificationStatus: data.domain_verification_status,
            senderVerificationStatus: data.sender_verification_status,
          },
        };
        dispatch.email.setState(subAccount);
      }

      return { ...subAccount };
    },

    async getOrCreateSubAccount(_, rootState): Promise<SubAccountReturnType> {
      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error } = await createSubAccountAPI();

      if (data.permission_url) {
        dispatch.email.setState({
          ...rootState.email,
          permissionURL: data.permission_url,
        });
      }

      let subAccount: any = {};
      if (data && !error) {
        subAccount = {
          subAccountId: data.sub_account_id,
          onboardingStatus: {
            dataSyncStatus: data.data_sync_status,
            domainVerificationStatus: data.domain_verification_status,
            senderVerificationStatus: data.sender_verification_status,
          },
        };
        dispatch.email.setState(subAccount);
      }
      dispatch.email.setState({
        isLoading: false,
      });

      return { ...subAccount };
    },

    async createSender(payload: {
      name: string;
      email: string;
    }): Promise<number> {
      const t = await getT(Router.locale, 'toasts');

      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error, status } = await createSenderAPI(payload);

      let senderId;
      if (data && !error) {
        senderId = data.id;

        dispatch.email.setOnboardingState({
          senderEmailVerificationSent: true,
        });

        dispatch.saveToast.showDone(t('verify_sender_success_toast'));

        await dispatch.email.refetchSubAccount();
      }

      if (error) {
        if (status === 409) {
          dispatch.saveToast.showDone(t('sender_created_toast'));

          dispatch.email.setOnboardingState({
            senderEmailVerificationSent: false,
          });
          dispatch.email.goToOnboardingStep({
            stepBy: 1,
          });

          await dispatch.email.refetchSubAccount();
        } else {
          dispatch.saveToast.showError(t('add_sender_error_toast'));
        }
      }

      dispatch.email.setState({
        isLoading: false,
      });

      return senderId;
    },

    async getDomain() {
      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error } = await fetchDomainAPI();

      if (data && !error && data.domain) {
        dispatch.email.setOnboardingState({ isDomainSuccessfullyAdded: true });
      }
      dispatch.email.setState({
        isLoading: false,
      });
    },

    async createDomain(payload: { domainName: string }) {
      const t = await getT(Router.locale, 'toasts');

      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error } = await createDomainAPI({
        domainName: payload.domainName,
      });

      if (data && !error) {
        dispatch.email.setOnboardingState({ isDomainSuccessfullyAdded: true });
      }

      if (error) {
        dispatch.saveToast.showError(t('adding_domain_create_error'));
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async verifySender(payload: { senderId: number; code: number }) {
      const t = await getT(Router.locale, 'toasts');

      dispatch.email.setState({
        isLoading: true,
      });
      const { error } = await validateSenderAPI(payload.senderId, payload.code);

      if (!error) {
        dispatch.email.setOnboardingState({
          senderEmailVerificationSent: false,
        });
        dispatch.email.goToOnboardingStep({
          stepBy: 1,
        });
        await dispatch.email.refetchSubAccount();
      }

      if (error) {
        dispatch.saveToast.showError(t('verify_sender_error_toast'));
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async initiateDataSync() {
      const t = await getT(Router.locale, 'toasts');

      dispatch.email.setState({
        isLoading: true,
      });

      const { error } = await createMerchantTaskAPI({
        task: MerchantTask.DATA_SYNC_SHOPIFY_TO_BREVO,
      });

      if (error) {
        dispatch.saveToast.showError(t('data_sync_error_toast'));
      } else {
        dispatch.email.goToOnboardingStep({
          stepBy: 1,
        });
        await dispatch.email.refetchSubAccount();
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async getDashboardRedirectURL(payload?: string): Promise<string> {
      dispatch.email.setState({
        isLoading: true,
      });

      const { data, error } = await fetchRedirectURLAPI(payload || '');

      let redirect_url = '';
      if (data && !error) {
        redirect_url = data.redirect_url;
      }

      dispatch.email.setState({
        isLoading: false,
      });

      return redirect_url;
    },

    isOnboardingCompleted(_, rootState): boolean {
      const { onboardingStatus } = rootState.email;
      const hasEmailPlan = dispatch.pricingV2.hasEmailPlan();

      if (!onboardingStatus) return false;

      return (
        onboardingStatus.dataSyncStatus === OnboardingStatus.COMPLETED &&
        onboardingStatus.senderVerificationStatus ===
          OnboardingStatus.COMPLETED &&
        hasEmailPlan
      );
    },

    getPersistOnboardingStepKey(_, rootState) {
      const { user } = rootState.user;

      return `${ONBOARDING_STEP_PERSIST_KEY}-${user.website.subdomain}`;
    },

    persistOnboardingStep(_, rootState) {
      const { currentStep } = rootState.email.onboarding;
      const storage = getStorage();
      storage.set(
        dispatch.email.getPersistOnboardingStepKey(),
        currentStep.toString(),
      );
    },

    resumeFromPersistedOnboardingStep() {
      const storage = getStorage();
      const step = storage.get(dispatch.email.getPersistOnboardingStepKey());
      try {
        const parsedStep = Number(step);
        if (parsedStep <= totalOnboardingSteps) {
          dispatch.email.setOnboardingState({
            currentStep: parsedStep,
          });
        }
      } catch {
        // do nothing
      }
    },

    clearPersistedOnboardingStep() {
      const storage = getStorage();
      storage.clear(dispatch.email.getPersistOnboardingStepKey());
    },
  }),

  reducers: {
    setOnboardingState(state, payload: Partial<EmailModelState['onboarding']>) {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
        },
      };
    },

    setState(state, payload: Partial<EmailModelState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default email;
