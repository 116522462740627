import { createModel } from '@rematch/core';
import _debounce from 'lodash.debounce';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import {
  fetchPriceDropConfigAPI,
  fetchPriceDropReportAPI,
  fetchPriceDropWidgetAPI,
  setPriceDropConfigAPI,
  setPriceDropWidgetAPI,
} from 'src/lib/api/automation/price-drop';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { AutomationConfig, PD_BIS_Report, Widget } from './types';

// debounced api call
const saveWidget = _debounce(
  (widget: any, successCallback: () => void, errorCallback: () => void) => {
    setPriceDropWidgetAPI(widget).then(res => {
      if (!res.error) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  },
  500,
);

interface PriceDropState {
  isChanged: boolean;
  isFetching: boolean;
  config: {
    enabled: boolean;
    metadata: AutomationConfig['metadata'];
  };
  report: PD_BIS_Report;
  widget: Widget;
}

const initialState = (): PriceDropState => ({
  isChanged: false,
  isFetching: true,
  config: {
    enabled: false,
    metadata: {
      title: '',
      description: '',
      redirect_url: '',
      actions: [],
    },
  },
  report: {
    pending: [],
    sent: [],
    summary: {
      attributed_revenue: 0,
      clicked: 0,
      delivered: 0,
      sent: 0,
      unique_variants: 0,
      in_queue: 0,
    },
  },
  widget: {
    enabled: false,
    metadata: {
      title: '',
      yes_button: {
        text: '',
      },
      post_subscription: {
        post_subscription_widget: {
          title: '',
        },
      },
    },
  },
});

const priceDropAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchConfig() {
      const { data, error } = await fetchPriceDropConfigAPI();

      if (!error) {
        this.storeState({ config: data });
      }
    },

    async fetchReportAndWidget() {
      const t = await getT(Router.locale, 'toasts');

      this.setFetching(true);

      const [
        { data: report, error: reportError },
        { data: widget, error: widgetError },
      ] = await Promise.all([
        fetchPriceDropReportAPI(),
        fetchPriceDropWidgetAPI(),
      ]);

      if (reportError || widgetError) {
        dispatch.saveToast.showError(t('Error fetching settings'));
        return;
      }

      this.storeState({ report, widget });
    },

    async toggle(payload: boolean, rootState) {
      const t = await getT(Router.locale, 'toasts');

      const state = rootState.priceDropAutomation;
      const newState = {
        ...state,
        widget: {
          ...state.widget,
          enabled: payload,
        },
      };

      this.storeState(newState);

      // make network request
      await setPriceDropWidgetAPI(newState.widget).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone(t('Changes saved'));
        } else {
          store.dispatch.saveToast.showError(t('Error saving changes'));
          this.storeState(state);
        }
      });
    },

    async saveConfigMetadata(
      payload: {
        actions: {
          title: string;
          redirect_url: string;
        }[];
        title: string;
        description: string;
        redirect_url: string;
        image: string;
      },
      rootState,
    ): Promise<{ data: any; error: any }> {
      const t = await getT(Router.locale, 'toasts');

      const state = rootState.priceDropAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          metadata: payload,
        },
      };

      // make network request
      return setPriceDropConfigAPI(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone(t('Changes saved'));
        } else {
          store.dispatch.saveToast.showError(t('Error saving changes'));
        }

        return res;
      });
    },

    async handleWidgetMetadataChange(payload: AnyObject, rootState) {
      const state = rootState.priceDropAutomation;

      const newState = {
        ...state,
        widget: {
          ...state.widget,
          metadata: payload,
        },
      };

      this.storeState(newState);
    },

    async saveWidgetMetadata(payload: AnyObject, rootState) {
      const t = await getT(Router.locale, 'toasts');

      const state = rootState.priceDropAutomation;
      const newState = {
        ...state,
        isChanged: true,
        widget: {
          ...state.widget,
          metadata: payload,
        },
      };

      this.storeState(newState);
      // make network request with debounced function
      saveWidget(
        newState.widget,
        () => {
          store.dispatch.saveToast.showDone(t('Changes saved'));
        },
        () => {
          this.storeState(state);
        },
      );
    },
  }),

  reducers: {
    setFetching(state, isFetching) {
      return {
        ...state,
        isFetching,
      };
    },
    storeState(state, payload) {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
  },
});

export default priceDropAutomation;
