import {
  BaseTreeNode,
  TreeNode,
  TreeNodeType,
} from '../components/FormWidget/lib/types';

/**
 * applies changes to a node in a tree in a mutable way, creates a new tree and returns it
 * although it seems like we mutate the node, the `applyChangesToNodeTree` creates a new node out of the mutated node.
 * This is directly inspired by how [immer](https://immerjs.github.io/immer/produce) returns new data after performing mutable operations inside a callback
 *
 * *API usage:*
 * ```
 * applyChangesToNodeTree(
 *   'nodeId',
 *   (node) => {
 *     node.attr.someAttr = someValue;
 *     node.children = someValue
 *   },
 *   tree
 *  );
 * ```
 */
export const applyChangesToNodeTree = (
  nodeId: string,
  callback: (node: TreeNode) => void,
  tree: TreeNode,
): TreeNode => {
  const cloneNode = (node: TreeNode): TreeNode => {
    if (node.id === nodeId) {
      callback(node);
    }
    if (
      node.children &&
      Array.isArray(node.children) &&
      // only box has children as of now
      node.type === TreeNodeType.BOX
    ) {
      return {
        ...node,
        children: node.children.map(child => cloneNode(child)),
      };
    }
    return { ...node };
  };
  return cloneNode(tree);
};

function isTreeNode(node: BaseTreeNode): node is TreeNode {
  return 'type' in node;
}

/**
 * searches for a node in a tree(`node`) by its id, goes to a maximum depth of 8,
 * if a tree is more than 8 levels deep, we might have a serious problem on the way we structure our tree
 */
export const getTreeNodeById = (
  node: TreeNode | TreeNode[],
  id: string,
  n = 8,
): TreeNode | null => {
  if (!Array.isArray(node)) {
    if (node.id === id) {
      return node;
    }

    if (n === 0 || !node.children) {
      return null; // If depth is 0 or node has no children, stop searching
    }

    const children = Array.isArray(node.children)
      ? node.children
      : [node.children];
    // enabling for loops
    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      if (typeof child !== 'string' && isTreeNode(child)) {
        const foundNode = getTreeNodeById(child, id, n - 1);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }

  return null;
};
