import { get, post, put } from './axios';

const BREVO_SUBACCOUNT_ENDPOINT = '/dashboard/brevo/sub-accounts/';

export const fetchSubAccountAPI = () => get(BREVO_SUBACCOUNT_ENDPOINT);

export const createSubAccountAPI = () => post(BREVO_SUBACCOUNT_ENDPOINT, {});

export const createSenderAPI = (payload: { email: string; name: string }) =>
  post(`${BREVO_SUBACCOUNT_ENDPOINT}senders/`, payload);

export const validateSenderAPI = (senderId: number, code: number) =>
  put(`${BREVO_SUBACCOUNT_ENDPOINT}senders/${senderId}/validate/`, {
    otp: code,
  });

export const fetchDomainAPI = () => get(`${BREVO_SUBACCOUNT_ENDPOINT}domains/`);

export const createDomainAPI = (payload: { domainName: string }) =>
  post(`${BREVO_SUBACCOUNT_ENDPOINT}domains/`, {
    domain_name: payload.domainName,
  });

export const authenticateDomainAPI = () =>
  put(`${BREVO_SUBACCOUNT_ENDPOINT}domains/validate/`, {});

export const fetchRedirectURLAPI = (payload = '') => {
  if (payload.length) {
    return post(`${BREVO_SUBACCOUNT_ENDPOINT}sso-redirect-url/`, {
      target_url: payload,
    });
  }
  return post(`${BREVO_SUBACCOUNT_ENDPOINT}sso-redirect-url/`, {});
};
