import { createModel } from '@rematch/core';
import _lodash from 'lodash';
import {
  getBrandDetails,
  getIndustries,
  getMerchantPreferences,
  updateOrCreateMerchantPreferences,
} from 'src/lib/api/integrated-onboarding';
import { getStorage } from 'src/lib/storage';
import { RootModel } from 'src/store/models';

export const IntegratedOnboardingStep = {
  ABOUT: 0,
  BRAND_SETUP: 1,
  INDUSTRY_AND_LANGUAGE: 2,
  WEBPUSH_OR_EMAIL: 3,
  ENABLE_THEME_APP_EXTENSION: 4,
} as const;

type IntegratedOnboardingState = {
  onboarding: OnboardingState;
  isLoading: boolean;
  isBrandAssetPageVisible: boolean;
  doPreferencesExist: boolean;
  brandAssetError: BrandAssetError;
};

export type BrandAssetError = {
  primary_color: boolean;
  secondary_color: boolean;
  logo: boolean;
};

export type OnboardingState = {
  currentStep: number;
  brand_url: string;
  logo_url: string;
  preferred_language: string;
  industry: string;
  product_sold: string;
  onboarding_setup_choice: 'webpush' | 'email' | 'neither';
  primary_color: string;
  secondary_color: string;
};

const initialState: IntegratedOnboardingState = {
  onboarding: {
    currentStep: IntegratedOnboardingStep.ABOUT,
    brand_url: '',
    logo_url: '',
    preferred_language: '',
    industry: '',
    product_sold: '',
    onboarding_setup_choice: 'neither',
    primary_color: '',
    secondary_color: '',
  },
  isLoading: false,
  isBrandAssetPageVisible: false,
  doPreferencesExist: false,
  brandAssetError: {
    logo: false,
    primary_color: false,
    secondary_color: false,
  },
};

const totalOnboardingSteps = Object.values(IntegratedOnboardingStep).filter(
  Number.isFinite,
).length;

const integratedOnboarding = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    goToOnboardingStep(
      payload: {
        stepBy?: number;
        jumpTo?: (typeof IntegratedOnboardingStep)[keyof typeof IntegratedOnboardingStep];
      },
      rootState,
    ) {
      if (typeof payload.jumpTo !== 'undefined') {
        const step = payload.jumpTo % totalOnboardingSteps;
        this.setOnboardingState({
          currentStep: step,
        });
        this.persistOnboardingStep();

        return;
      }

      if (typeof payload.stepBy !== 'undefined') {
        const step =
          Math.abs(
            rootState.integratedOnboarding.onboarding.currentStep +
              payload.stepBy,
          ) % totalOnboardingSteps;
        this.setOnboardingState({
          currentStep: step,
        });
        this.persistOnboardingStep();
        this.syncOnboardingState();
      }
    },

    getPersistOnboardingStepKey(_, rootState) {
      const { user } = rootState.user;

      return `integrated-onboarding-step-${user.website.subdomain}`;
    },

    persistOnboardingStep(_, rootState) {
      const { currentStep } = rootState.integratedOnboarding.onboarding;
      const storage = getStorage();
      storage.set(
        dispatch.integratedOnboarding.getPersistOnboardingStepKey(),
        currentStep.toString(),
      );
    },

    resumeFromPersistedOnboardingStep() {
      const storage = getStorage();
      const step = storage.get(
        dispatch.integratedOnboarding.getPersistOnboardingStepKey(),
      );
      try {
        const parsedStep = Number(step);
        if (parsedStep <= totalOnboardingSteps) {
          dispatch.integratedOnboarding.setOnboardingState({
            currentStep: parsedStep,
          });
        }
      } catch {
        // do nothing
      }
    },

    clearPersistedOnboardingStep() {
      const storage = getStorage();
      storage.clear(
        dispatch.integratedOnboarding.getPersistOnboardingStepKey(),
      );
    },

    async extractLogoFromSubAccount() {
      try {
        this.setIsLoading(true);
        const { error, data } = await getBrandDetails();
        if (error) {
          this.setBrandAssetError({
            primary_color: true,
            secondary_color: true,
            logo: true,
          });
          this.setOnboardingState({
            logo_url: '',
            primary_color: '#000000',
            secondary_color: '#FEF4F2',
          });
          dispatch.saveToast.showError('Error fetching brand details');
          this.setIsLoading(false);
          return;
        }

        const primColor = data.primary_color[0] ?? '#000000';
        const secondary_color = data.secondary_color[0] ?? '#FEF4F2';
        const logo = data?.logo_url ?? '';
        if (!data.primary_color || !data.secondary_color || !data.logo_url) {
          this.setBrandAssetError({
            primary_color: Boolean(data?.primary_color), // we get empty array if no color and Boolean of empty array is true
            secondary_color: Boolean(data?.secondary_color), // we get empty array if no color and Boolean of empty array is true
            logo: Boolean(!data?.logo_url), // we get null if no logo and Boolean of null is false that's why we negate it
          });
          dispatch.saveToast.showError('Error fetching website colors');
        }
        this.setOnboardingState({
          logo_url: logo,
          primary_color: primColor,
          secondary_color,
        });
        this.setIsLoading(false);
        return;
      } catch {
        dispatch.saveToast.showError('Error fetching brand details');
        this.setIsLoading(false);
      }
    },

    async fetchIndustries() {
      try {
        const { data, error } = await getIndustries();
        if (error) {
          dispatch.saveToast.showError('Error fetching industries');
          return [];
        }

        return data.map(i => {
          return { value: i.value, label: i.display };
        });
      } catch {
        return [];
      }
    },

    /**
     * This only runs when a merchant presses the next button
     * if there is only one field in the state, it means the record doesn't exist in the backend yet
     * so we create a record in the backend with a POST req
     * so that we can update it later with the PUT req from the else block
     */
    async syncOnboardingState(_, rootState) {
      const state = { ...rootState.integratedOnboarding.onboarding };
      delete state.currentStep;
      if (state.onboarding_setup_choice === 'neither') {
        delete state.onboarding_setup_choice;
      }
      const stateWithNoEmptyFields = _lodash.omitBy(state, x => !x);

      if (Object.keys(stateWithNoEmptyFields).length) {
        if (!rootState.integratedOnboarding.doPreferencesExist) {
          const { error } = await updateOrCreateMerchantPreferences(
            stateWithNoEmptyFields as OnboardingState,
            true,
          );
          if (error) {
            dispatch.saveToast.showError('Error creating merchant preferences');
          } else {
            rootState.integratedOnboarding.doPreferencesExist = true;
          }
        } else {
          const { error } = await updateOrCreateMerchantPreferences(
            stateWithNoEmptyFields as OnboardingState,
          );
          if (error) {
            dispatch.saveToast.showError('Error updating merchant preferences');
          }
        }
      }
    },

    async fetchMerchantPreferences(_, rootState) {
      try {
        dispatch.integratedOnboarding.setIsLoading(true);
        const { data, error } = await getMerchantPreferences();

        if (error) {
          if ((error as any)?.errorType === 'PreferencesNotFound') {
            rootState.integratedOnboarding.doPreferencesExist = false;
          }
          if (
            rootState.integratedOnboarding.onboarding.currentStep !==
            IntegratedOnboardingStep.ABOUT
          ) {
            dispatch.saveToast.showError('Error fetching merchant preferences');
          }
        } else {
          rootState.integratedOnboarding.doPreferencesExist = true;
        }
        this.setOnboardingState({
          brand_url: data?.brand_url ?? '',
          logo_url: data?.logo_url ?? '',
          industry: data?.industry ?? '',
          preferred_language: data?.preferred_language ?? '',
          product_sold: data?.product_sold ?? '',
          onboarding_setup_choice: data?.onboarding_setup_choice ?? 'neither',
          primary_color: data?.primary_color ?? '',
          secondary_color: data?.secondary_color ?? '',
        });
        dispatch.integratedOnboarding.setIsLoading(false);
      } catch {
        dispatch.saveToast.showError('Error fetching merchant preferences');
        dispatch.integratedOnboarding.setIsLoading(false);
      }
    },
  }),

  reducers: {
    setOnboardingState(
      state,
      payload: Partial<IntegratedOnboardingState['onboarding']>,
    ) {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
        },
      };
    },
    setIsBannerVisible(state, payload: boolean) {
      return {
        ...state,
        isBrandAssetPageVisible: payload,
      };
    },
    setIsLoading(state, payload: boolean) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    setBrandAssetError(state, payload: Partial<BrandAssetError>) {
      return {
        ...state,
        brandAssetError: {
          ...state.brandAssetError,
          ...payload,
        },
      };
    },
  },
});

export default integratedOnboarding;
