import { Component } from 'react';
import {
  BrowserClient,
  defaultStackParser,
  makeFetchTransport,
  FunctionToString,
  Dedupe,
  HttpContext,
  getCurrentHub,
} from '@sentry/browser';
import { logErrorToSentry } from './debug-utils';

class LoggingErrorBoundary extends Component<{ children: React.ReactNode }> {
  componentDidMount() {
    const client = new BrowserClient({
      dsn:
        process.env.NODE_ENV === 'production'
          ? 'https://aea75c3e7677488fb4ee1cfa49993793@sentry.io/1891624'
          : null,
      release: process.env.BUILD_ID,
      environment: process.env.NODE_ENV,
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [new Dedupe(), new FunctionToString(), new HttpContext()],
    });

    getCurrentHub().bindClient(client);
  }
  componentDidCatch(error, errorInfo) {
    logErrorToSentry({ error, extras: errorInfo });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default LoggingErrorBoundary;
