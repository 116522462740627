/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import { ChannelType } from 'src/lib/constants';
import { roundOffTwoDecimals } from 'src/lib/utils';
import {
  ChargeType,
  CouponCodeRule,
  Plan,
  PlanType,
  PricingCategory,
  PricingPlans,
  PricingSlab,
} from './models/types';

export const getPricingSlabs = ({
  plans,
  planType,
  chargeType,
}: {
  plans?:
    | PricingPlans['email']
    | PricingPlans['webpush']
    | PricingPlans['omni'];
  chargeType: ChargeType;
  planType: PlanType;
}): PricingSlab[] => plans?.[chargeType]?.[planType]?.[0]?.pricing_slabs || [];

/**
 * combines all webpush plan type slabs into a single sorted array of slabs
 */
export const denormaliseWebpushPricingSlabs = plans => {
  try {
    const annualWebpushOnlySlabs: PricingSlab[] = getPricingSlabs({
      plans,
      planType: 'enterprise',
      chargeType: 'annual',
    })
      .concat(
        getPricingSlabs({
          plans,
          planType: 'business',
          chargeType: 'annual',
        }),
      )
      // adding free plan to the annual category to balance off both annual and monthly categories
      .concat(
        getPricingSlabs({
          plans,
          planType: 'free',
          chargeType: 'monthly',
        }),
      );

    const monthlyWebpushOnlySlabs: PricingSlab[] = getPricingSlabs({
      plans,
      planType: 'enterprise',
      chargeType: 'monthly',
    })
      .concat(
        getPricingSlabs({
          plans,
          planType: 'business',
          chargeType: 'monthly',
        }),
      )
      .concat(
        getPricingSlabs({
          plans,
          planType: 'free',
          chargeType: 'monthly',
        }),
      );

    annualWebpushOnlySlabs.sort(
      (a, b) => a.attribution_limit - b.attribution_limit,
    );

    monthlyWebpushOnlySlabs.sort(
      (a, b) => a.attribution_limit - b.attribution_limit,
    );

    return {
      annual: _.uniqBy(annualWebpushOnlySlabs, 'attribution_limit'),
      monthly: _.uniqBy(monthlyWebpushOnlySlabs, 'attribution_limit'),
    };
  } catch {
    return {
      annual: [],
      monthly: [],
    };
  }
};

export const getDefaultPricingSlabs = (
  currentPlan: Plan,
  pricingPlans: PricingPlans,
) => {
  const monthlyFreeEmailPlan = getPricingSlabs({
    plans: pricingPlans.email,
    planType: 'enterprise',
    chargeType: 'monthly',
  })[0];
  const monthlyFreeWebpushPlan = getPricingSlabs({
    plans: pricingPlans.omni,
    planType: 'enterprise',
    chargeType: 'monthly',
  }).find(slab => slab.channel === ChannelType.WEBPUSH);

  const selectedSlabs = {
    email: monthlyFreeEmailPlan?.sku || '',
    webpush: monthlyFreeWebpushPlan?.sku || '',
    webpushOnlyIdx: 0,
  };

  if (currentPlan) {
    currentPlan.pricing_slabs.forEach(slab => {
      // is omni email plan
      if (slab.channel === ChannelType.EMAIL) {
        const slabExists = getPricingSlabs({
          plans: pricingPlans.email,
          planType: 'enterprise',
          chargeType: currentPlan.charge_type,
        }).find(eSlab => slab.sku === eSlab.sku);
        if (slabExists) {
          selectedSlabs.email = slab.sku;
        }
      } else if (slab.channel === ChannelType.WEBPUSH) {
        // is webpush omni plan
        if (currentPlan.sku.includes('omni')) {
          const slabExists = getPricingSlabs({
            plans: pricingPlans.omni,
            planType: 'enterprise',
            chargeType: currentPlan.charge_type,
          }).find(eSlab => slab.sku === eSlab.sku);
          if (slabExists) {
            selectedSlabs.webpush = slab.sku;
          }
        } else {
          // is webpush only plan, then find the nearest slab that matches the current plan's impressions limit
          const slabExists = pricingPlans.denormalisedWebpushOnlyPricingSlabs[
            currentPlan.charge_type
          ].findIndex(
            (wpSlab: PricingSlab) =>
              wpSlab.attribution_limit >= slab.attribution_limit,
          );

          if (slabExists !== -1) {
            selectedSlabs.webpushOnlyIdx = slabExists;
          }
        }
      }
    });
  }

  return selectedSlabs;
};

export const isSelectedSlabLessThanCurrentSlab = (
  currentPricingSlab: PricingSlab,
  selectedPricingSlab: PricingSlab,
) => {
  if (currentPricingSlab && selectedPricingSlab) {
    return (
      currentPricingSlab.attribution_limit >
      selectedPricingSlab.attribution_limit
    );
  }

  return false;
};

export const canApplyCouponCode = ({
  appliesTo,
  current,
}: {
  appliesTo: CouponCodeRule['appliesTo'];
  current: {
    planType: PlanType;
    chargeType: ChargeType;
    billingPlanSku: string;
    pricingSlabSku: string;
  };
}) => {
  const isMonthly = current.chargeType === 'monthly';
  if (appliesTo.plans?.length > 0) {
    // If SKU exists it takes priority and other rule will be ignored
    return (
      appliesTo.plans.includes(current.billingPlanSku) ||
      appliesTo.plans.includes(current.pricingSlabSku)
    );
  }

  const hasPlanType = appliesTo.planTypes.includes(current.planType);

  if (appliesTo.billingInterval === 'monthly') {
    if (appliesTo.planTypes.length > 0) {
      return hasPlanType && isMonthly;
    }
    return isMonthly;
  }

  if (appliesTo.billingInterval === 'annual') {
    if (appliesTo.planTypes.length > 0) {
      return hasPlanType && !isMonthly;
    }
    return !isMonthly;
  }

  if (appliesTo.planTypes.length > 0) return hasPlanType;

  return false;
};

export const getDiscountPrice = ({
  price,
  coupon,
}: {
  price: string;
  coupon: CouponCodeRule;
}) => {
  const { amountOff, percentageOff, discountType } = coupon;
  const parsedPrice = parseFloat(price);

  if (discountType === 'percentage') {
    return roundOffTwoDecimals(
      parsedPrice - (parsedPrice * percentageOff) / 100,
    );
  }

  if (discountType === 'amount') {
    return roundOffTwoDecimals(parsedPrice - amountOff);
  }

  return roundOffTwoDecimals(parsedPrice);
};

export const isExistingPricingSlab = () => {};

export const switchToAppliedCouponPlan = ({
  appliedTo,
  plans,
}: {
  appliedTo: CouponCodeRule['appliesTo'];
  plans: PricingPlans;
}): { chargeType?: ChargeType; category: PricingCategory } => {
  const res = {
    chargeType: undefined,
    category: undefined,
  };

  if (appliedTo.plans?.length > 0) {
    const sku = appliedTo.plans[0];
    const billingPlan = getTopLevelBillingPlanBySku(sku, plans);

    if (billingPlan) {
      let billingPlanCategory: PricingCategory;
      // this is the only way to determine the category of the billing plan
      if (billingPlan.sku.includes('omni')) {
        billingPlanCategory = 'omni';
      } else if (billingPlan.sku.includes('webpush')) {
        billingPlanCategory = 'webpush';
      } else if (billingPlan.sku.includes('email')) {
        billingPlanCategory = 'email';
      }

      res.category = billingPlanCategory;
      res.chargeType = billingPlan.charge_type;
    }

    return res;
  }

  if (appliedTo.billingInterval) {
    res.chargeType =
      appliedTo.billingInterval === 'all' ? '' : appliedTo.billingInterval;
  }

  return res;
};

/**
 * given the billing plan configuration, returns the top level billing plan
 */
export const getTopLevelBillingPlanByConfig = ({
  category,
  plans,
  chargeType,
  planType,
}: {
  category: PricingCategory;
  plans: PricingPlans;
  chargeType: ChargeType;
  planType: PlanType;
}): Plan => {
  try {
    const { webpush, omni, email } = plans;

    switch (category) {
      case 'webpush': {
        const topLevelBillingPlan = webpush[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      case 'omni': {
        const topLevelBillingPlan = omni[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      case 'email': {
        const topLevelBillingPlan = email[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      default:
        return null;
    }
  } catch {
    return null;
  }
};

/**
 * given a sku, returns the top level billing plan
 * !!! this is an expensive function !!!
 */
export const getTopLevelBillingPlanBySku = (
  sku: string,
  plans: PricingPlans,
) => {
  const allCategories: PricingCategory[] = ['webpush', 'omni', 'email'];
  const allChargeTypes: ChargeType[] = ['monthly', 'annual'];
  const allPlanTypes: PlanType[] = ['enterprise', 'business', 'free'];

  // this is the only readable way to accumulate all billing plans :(
  for (const category of allCategories) {
    for (const chargeType of allChargeTypes) {
      for (const planType of allPlanTypes) {
        const topLevelBillingPlan = getTopLevelBillingPlanByConfig({
          category,
          plans,
          chargeType,
          planType,
        });

        if (topLevelBillingPlan?.sku === sku) {
          return topLevelBillingPlan;
        }
      }
    }
  }

  return null;
};
