import { createModel } from '@rematch/core';
import { isPopupPromptFormWidget } from 'src/lib/utils';
import { FormWidget } from 'src/modules/optins/models/types';
import {
  applyChangesToNodeTree,
  getTreeNodeById,
} from 'src/modules/optins/util/treeUtils';
import { RootModel } from 'src/store/models';
import _set from 'lodash.set';
import { OptinType } from 'src/lib/constants';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { TreeNode, TreeNodeType } from '../../FormWidget/lib/types';
import { ReservedContainerNodeId } from '../../FormWidget/lib/constants';

interface FormWidgetEditorWorkingState {
  workingFormWidget: FormWidget;
  selectedFormWidgetTreeNodeId: string;
  isChanged: boolean;
  isSettingsAddonOpen: boolean;
}

const initialState: () => FormWidgetEditorWorkingState = () => ({
  workingFormWidget: null,
  selectedFormWidgetTreeNodeId: null,
  isChanged: false,
  isSettingsAddonOpen: false,
});

const formWidgetEditor = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    setWorkingFormWidget(
      payload: {
        formWidget: FormWidget;
        defaultNodeToSelect?: string;
      },
      rootState,
    ) {
      this.storeConfig({
        workingFormWidget: payload.formWidget,
        isSettingsAddonOpen: false,
        selectedFormWidgetTreeNodeId:
          payload.defaultNodeToSelect ||
          rootState.formWidgetEditor.selectedFormWidgetTreeNodeId,
      });
    },

    setSelectedWidgetTreeNode(payload: { id: string }) {
      this.storeConfig({
        selectedFormWidgetTreeNodeId: payload.id,
        isSettingsAddonOpen: false,
      });
    },

    /**
     * @see {@link applyChangesToNodeTree}
     */
    setNodeContent(
      payload: {
        nodeId: string;
        updateWith: (node: TreeNode) => void;
      },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      if (
        workingFormWidget &&
        workingFormWidget.config &&
        isPopupPromptFormWidget(workingFormWidget)
      ) {
        workingFormWidget.config = applyChangesToNodeTree(
          payload.nodeId,
          payload.updateWith,
          workingFormWidget.config,
        );
        this.storeConfig({
          workingFormWidget,
          isChanged: true,
        });
      }
    },

    async setActivePromptWidget(payload: { activeType: OptinType }, rootState) {
      const t = await getT(Router.locale, 'toasts');

      const activeConfig = {
        [OptinType.BROWSER_WEBPUSH]: 'oneStep',
        [OptinType.CUSTOM_WEBPUSH]: 'twoStep',
      }[payload.activeType];

      const { workingFormWidget } = rootState.formWidgetEditor;

      _set(workingFormWidget, `config.active`, activeConfig);

      this.storeConfig({
        workingFormWidget,
      });

      // update the form widget itself first
      await dispatch.formWidgetEditor.saveFormWidget({ silent: true });
      // update the node type then
      await dispatch.optins.saveOptinFlowNodeOptions({
        optin_type: payload.activeType,
      });

      dispatch.saveToast.showDone(t('Prompt type updated'));
    },

    setPromptWidgetProp(
      payload: { path: string; value: any; isTextContent?: boolean },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      _set(
        workingFormWidget,
        payload.isTextContent
          ? `config.twoStep.${payload.path}.default`
          : `config.twoStep.${payload.path}`,
        payload.value,
      );

      this.storeConfig({
        workingFormWidget,
        isChanged: true,
      });
    },

    async saveFormWidget(payload: { silent?: boolean }, rootState) {
      await dispatch.optins.saveFormWidgetTree({
        silent: Boolean(payload?.silent),
        workingTree: rootState.formWidgetEditor.workingFormWidget.config,
      });
      this.storeConfig({
        isChanged: false,
      });
    },

    discardFormWidgetChanges() {
      dispatch.optins.syncCurrentTreeOntoWorkingTree();
      this.storeConfig({
        isChanged: false,
      });
    },

    toggleSettingsAddon(payload: { state: boolean }, rootState) {
      this.storeConfig({
        isSettingsAddonOpen: payload.state,
        selectedFormWidgetTreeNodeId: payload.state
          ? null
          : rootState.formWidgetEditor.selectedFormWidgetTreeNodeId,
      });
    },

    changeImagePositioning(
      payload: { position: 'left' | 'right' | 'top' },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      if (
        workingFormWidget &&
        workingFormWidget.config &&
        isPopupPromptFormWidget(workingFormWidget)
      ) {
        const oldImageNode = getTreeNodeById(
          workingFormWidget.config,
          ReservedContainerNodeId.IMAGE,
        );

        if (oldImageNode) {
          // reset the Box(image) node
          const imageNode = { ...oldImageNode };
          imageNode.attr = {
            // eslint-disable-next-line dot-notation
            bgUrl: oldImageNode.attr['bgUrl'],
          };

          const contentNode = getTreeNodeById(
            workingFormWidget.config,
            'container_1', // hardcoded for now
          );

          switch (payload.position) {
            case 'left': {
              workingFormWidget.config = applyChangesToNodeTree(
                'root',
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'LR';
                    node.children = [{ ...imageNode }, { ...contentNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            case 'right': {
              workingFormWidget.config = applyChangesToNodeTree(
                'root',
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'LR';
                    node.children = [{ ...contentNode }, { ...imageNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            case 'top': {
              workingFormWidget.config = applyChangesToNodeTree(
                'root',
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'TB';
                    // eslint-disable-next-line dot-notation
                    imageNode.attr['fill'] = '40%';
                    node.children = [{ ...imageNode }, { ...contentNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            default:
              break;
          }
        }

        this.storeConfig({
          workingFormWidget,
          isChanged: true,
        });
      }
    },

    setFormWidgetProps(
      payload: Partial<Omit<FormWidget, 'config' | 'id'>>,
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      this.storeConfig({
        workingFormWidget: {
          ...workingFormWidget,
          ...payload,
        },
      });
    },

    resetState() {
      this.storeConfig(initialState());
    },
  }),

  reducers: {
    storeConfig(
      state: FormWidgetEditorWorkingState,
      payload: FormWidgetEditorWorkingState,
    ): FormWidgetEditorWorkingState {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default formWidgetEditor;
