export enum OnboardingStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum EmailOnboardingStep {
  ABOUT_1,
  ABOUT_2,
  SYNC_DATA,
  SETUP_SENDER,
  ADD_DOMAIN,
  PRICING,
}
