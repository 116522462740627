import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { debugLog } from '../../debug-utils';
import { getStorage } from '../../storage';
import { getUser } from './index';

export const axiosInterceptResponseErrorHandler = error => {
  const user = getUser();
  debugLog({
    message: !error?.response ? 'empty response' : 'api failed',
    data: {
      error,
    },
  });

  if (![401, 403].includes(error?.response?.status))
    return Promise.reject(error);

  const storage = getStorage();
  const platform = user?.website?.platform || 'shopify';

  // clear storage and navigate to home/login page only when app is not embedded

  storage.clear();
  storage.clearAll();

  // Save current path to redirect to later after login, except when its homepage bcoz
  // that open by default
  if (window.location.pathname !== '/') {
    const redirectObj = {
      subdomain: window._po_subdomain,
      nextURL: btoa(
        JSON.stringify({ path: window.location.pathname, params: {} }),
      ),
    };
    storage.set('redirect_on_load', redirectObj);
  }

  if (platform !== 'shopify') {
    window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/v1/pushowl/${platform}/?subdomain=${user.website.subdomain}&reason=unauthorised_token`;
  }

  // on the embedded view, chances of getting a 401 or 403 is very slim, so ignore it
  return Promise.reject(error);
};

/**
 * This interceptor will append the platform
 * query parameter to the request URL
 */

const URLsWithoutPlatform = [/\b(upload(-staging)?\.pushowl\.com)\b/g];

export const axiosInterceptorPlatformHandler = (req: AxiosRequestConfig) => {
  const user = getUser();
  if (!user?.website?.platform) return req;

  // constructor requires an absolute URL which is to be removed later
  const url = new URL(`${req.baseURL}${req.url}`);

  if (url.searchParams.has('platform')) return req;

  url.searchParams.append('platform', user?.website?.platform);

  if (!URLsWithoutPlatform.find(regex => regex.test(req.url))) {
    // req.url takes in a relative URL, remove baseURL here
    req.url = url.toString().replace(req.baseURL, '');
  }

  return req;
};

export const axiosInterceptRequestHandler = async (
  req: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  if (window._po_disabled)
    return {
      ...req,
      cancelToken: new Axios.CancelToken(cancel =>
        cancel('Session disabled. Network requests are disallowed.'),
      ),
    };

  const user = getUser();

  // Following endpoints don't require JWT
  if (
    ![
      /auth\/support/,
      /authenticate/,
      /s3\.amazonaws/,
      /billing\/init(?!\/email)/,
    ].find(regex => regex.test(req.url))
  )
    req.headers.Authorization = `JWT ${user.token}`;

  return req;
};

/**
 * Evaluates retry criteria for a failed network request.
 *
 * axios-retry checks for error.config
 * Status codes that are safe to retry: 429, 502, 503
 * Network errors are only to be tried with safe methods.
 *
 * @param error AxiosError
 * @returns boolean
 */
export const isRetryable = (error: AxiosError) => {
  const isNetworkError = /(timeout of \d+ms exceeded)|(Network Error)/.test(
    error.message,
  );

  // Image upload is a POST request. We usually don't retry POST but we are make an exception for it
  const isFileUploadURL = /\b(upload(-staging)?\.pushowl\.com)\b/g.test(
    error.config.url,
  );
  const isAllowedStatus = [429, 502, 503].includes(error.response?.status);
  const isAllowedMethod = ['delete', 'get', 'options', 'head'].includes(
    error.config.method,
  );

  return (
    error.config &&
    (isAllowedStatus ||
      (isNetworkError && (isAllowedMethod || isFileUploadURL)))
  );
};
