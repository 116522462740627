import { CSSProperties } from 'react';

export enum TreeNodeType {
  BOX = 'BOX',
  TEXT = 'TEXT',
  INPUT = 'INPUT',
  IMAGE = 'IMAGE',
  BUTTON = 'BUTTON',
  CHECKBOX = 'CHECKBOX',
}

export type Alignment = 'left' | 'top' | 'bottom' | 'right' | 'center';

export type TreeNodeAlignment = Alignment | [Alignment, Alignment];

export type Direction = 'LR' | 'TB';

export type Fill = boolean | string;

export type Size =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '0';

export interface BaseTreeNode {
  id: string;
  type: TreeNodeType;
  attr?: unknown;
  version?: `${number}.${number}.${number}`;
  children?: string | BaseTreeNode[];
}

type BaseTreeNodeAttributes<T> = T & { css?: React.CSSProperties };

export type TreeNode =
  | (BaseTreeNode & {
      type: TreeNodeType.BOX;
      attr?: BaseTreeNodeAttributes<
        Partial<{
          align: TreeNodeAlignment;
          dir: Direction;
          bgUrl: string;
          pad: Size;
          bgColor: string;
          fill: Fill;
        }>
      >;
      children?: TreeNode[];
    })
  | (BaseTreeNode & {
      type: TreeNodeType.TEXT;
      attr?: BaseTreeNodeAttributes<
        Partial<{
          color: string;
          size: Size;
        }>
      >;
      children: string;
    })
  | (BaseTreeNode & {
      type: TreeNodeType.IMAGE;
      attr?: BaseTreeNodeAttributes<{
        url: string;
      }>;
    })
  | (BaseTreeNode & {
      type: TreeNodeType.INPUT;
      attr?: BaseTreeNodeAttributes<{
        color: string;
      }>;
      children: string;
    })
  | (BaseTreeNode & {
      type: TreeNodeType.CHECKBOX;
      attr?: BaseTreeNodeAttributes<{
        color: string;
        size: Size;
      }>;
      children: string;
    })
  | (BaseTreeNode & {
      type: TreeNodeType.BUTTON;
      attr?: BaseTreeNodeAttributes<{
        color: string;
        bgColor: string;
      }>;
      children: string;
    });

export type TreeNodeByType<T = keyof typeof TreeNodeType> = Extract<
  TreeNode,
  { type: T }
>;

export type OverrideNodes = Record<
  string,
  | (({
      defaultStyles,
      node,
    }: {
      defaultStyles: CSSProperties;
      node: TreeNode;
    }) => JSX.Element)
  | string
>;
