import { createModel } from '@rematch/core';
import {
  AllTimeTimeFilter,
  DaysInSeconds,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import {
  CampaignTypes,
  OldTimeFilter,
} from 'src/components/ReportSaver/models';
import { RootModel } from 'src/store/models';
import {
  fetchPausedCampaignsAPI,
  fetchReportingSegmentsAPI,
  fetchScheduledCampaignsAPI,
  fetchSentCampaignsAPI,
  fetchTotalCampaignStatsAPI,
} from 'src/lib/api/reports';
import {
  deleteCampaignAPI,
  fetchCampaignsAPI,
  resendCampaignAPI,
} from 'src/lib/api/campaigns';
import getT from 'next-translate/getT';
import Router from 'next/router';

export enum CampaignType {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  PAUSED = 'paused',
  DRAFT = 'draft',
}
interface Campaigns {
  isFetching: boolean;
  page: number;
  totalCount: number;
  campaigns: AnyObject[];
}

interface DraftCampaigns {
  isFetching: boolean;
  limit: number;
  previousOffset: number;
  totalCount: null | number;
  campaigns: AnyObject[];
  hasMore: boolean;
}

export interface CampaignsModelState {
  isFetching: boolean;
  sent: Campaigns;
  scheduled: Campaigns;
  paused: Campaigns;
  draft: DraftCampaigns;
  reports: {
    isFetching: boolean;
    timeFilter: TimeFilter;
    campaignTypes: CampaignTypes[];
    segmentsList: { id: number; name: string; campaignsSent: number }[];
    segmentIds: string[];
    totalStats: {
      impressions: number;
      clicks: number;
      ctr: number;
      revenue: number;
    };
  };
}

const initialState: CampaignsModelState = {
  isFetching: true,
  sent: {
    isFetching: true,
    page: 1,
    campaigns: [],
    totalCount: 0,
  },
  scheduled: {
    isFetching: true,
    page: 1,
    campaigns: [],
    totalCount: 0,
  },
  paused: {
    isFetching: true,
    page: 1,
    campaigns: [],
    totalCount: 0,
  },
  draft: {
    isFetching: true,
    limit: 10,
    previousOffset: 0,
    campaigns: [],
    totalCount: null,
    hasMore: false,
  },
  reports: {
    isFetching: true,
    timeFilter: AllTimeTimeFilter,
    campaignTypes: [
      CampaignTypes.REGULAR,
      CampaignTypes.FLASH,
      CampaignTypes.SMART,
    ],
    segmentsList: [],
    segmentIds: [],
    totalStats: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      revenue: 0,
    },
  },
};

const campaigns = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async updateFiltersAndFetch(payload: {
      segmentIds?: string[];
      campaignTypes?: string[];
      // TODO:aftermigration - use the new time filter type only
      timeFilter?: TimeFilter | OldTimeFilter;
    }) {
      this.setReports(payload);
      this.resetSentRecordsPerPage();
      this.fetchSentCampaigns();
      this.fetchTotalStats([CampaignType.SENT]);
    },

    async fetchReportingSegments() {
      const t = await getT(Router.locale, 'toasts');

      const { error, segmentsList } = await fetchReportingSegmentsAPI();

      if (error) {
        dispatch.saveToast.showError(t('Error fetching segment'));
        return;
      }

      this.setReports({ segmentsList });
    },

    async fetchTotalStats(states: CampaignType[], rootState) {
      this.setReports({ isFetching: true });

      const {
        settings: { attributionWindow },
        campaigns,
      } = rootState;

      const {
        reports: { campaignTypes, segmentIds, timeFilter },
      } = campaigns;

      const { sent, scheduled, paused } = await fetchTotalCampaignStatsAPI(
        states,
        {
          timeFilter,
          campaignTypes,
          segmentIds,
        },
        attributionWindow,
      );

      if (sent) {
        const {
          impressions,
          clicks,
          click_rate: ctr,
          revenue,
          count: totalCount,
        } = sent;
        this.setReports({ totalStats: { impressions, clicks, ctr, revenue } });
        this.setSentState({ totalCount });
      }

      if (scheduled) this.setScheduledState({ totalCount: scheduled.count });

      if (paused) this.setPausedState({ totalCount: paused.count });

      this.setReports({ isFetching: false });
    },

    async fetchSentCampaigns(
      {
        segmentIds: payloadSegmentIds,
        pageRequest = false,
      }: { segmentIds?: string[]; pageRequest?: boolean } = {},
      rootState,
    ) {
      const t = await getT(Router.locale, 'toasts');

      this.setSentState({ isFetching: true });

      const {
        settings: { attributionWindow },
        campaigns,
      } = rootState;

      const {
        reports: { campaignTypes, segmentIds, timeFilter },
        sent: { page, campaigns: fetchedCampaigns },
      } = campaigns;

      const { error, reports: sentCampaigns } = await fetchSentCampaignsAPI(
        {
          timeFilter,
          campaignTypes,
          segmentIds: payloadSegmentIds || segmentIds,
          page: pageRequest ? page + 1 : 1,
        },
        attributionWindow,
      );

      if (error) {
        this.setSentState({ isFetching: false });
        dispatch.saveToast.showError(t('Error fetching campaigns'));
        return;
      }

      this.setSentState({
        isFetching: false,
        campaigns: pageRequest
          ? [...fetchedCampaigns, ...sentCampaigns]
          : sentCampaigns,
        page: pageRequest ? page + 1 : 1,
      });
    },

    async fetchScheduledCampaigns(
      { pageRequest = false }: { pageRequest?: boolean } = {},
      rootState,
    ) {
      const t = await getT(Router.locale, 'toasts');

      const {
        settings: { attributionWindow },
        campaigns: {
          scheduled: { page, campaigns: fetchedCampaigns },
        },
      } = rootState;
      this.setScheduledState({ isFetching: true });

      const { error, reports: campaigns } = await fetchScheduledCampaignsAPI(
        {
          timeFilter: { value: DaysInSeconds.ALL_TIME },
          campaignTypes: [CampaignTypes.ALL],
          segmentIds: [],
          page: pageRequest ? page + 1 : 1,
        },
        attributionWindow,
      );

      if (error) {
        this.setPausedState({ isFetching: false });
        dispatch.saveToast.showError(t('Error fetching campaigns'));
        return;
      }

      this.setScheduledState({
        isFetching: false,
        campaigns: pageRequest
          ? [...fetchedCampaigns, ...campaigns]
          : campaigns,
        page: pageRequest ? page + 1 : 1,
      });
    },

    async fetchPausedCampaigns(
      { pageRequest = false }: { pageRequest?: boolean } = {},
      rootState,
    ) {
      const t = await getT(Router.locale, 'toasts');

      const {
        settings: { attributionWindow },
        campaigns: {
          paused: { page, campaigns: fetchedCampaigns },
        },
      } = rootState;
      this.setPausedState({ isFetching: true });

      const { error, reports: campaigns } = await fetchPausedCampaignsAPI(
        {
          timeFilter: { value: DaysInSeconds.ALL_TIME },
          campaignTypes: [CampaignTypes.ALL],
          segmentIds: [],
          page: pageRequest ? page + 1 : 1,
        },
        attributionWindow,
      );

      if (error) {
        this.setPausedState({ isFetching: false });
        dispatch.saveToast.showError(t('Error fetching campaigns'));
        return;
      }

      this.setPausedState({
        isFetching: false,
        campaigns: pageRequest
          ? [...fetchedCampaigns, ...campaigns]
          : campaigns,
        page: pageRequest ? page + 1 : 1,
      });
    },

    async fetchCampaigns(
      payload: {
        limit?: number;
        offset: number;
        type: CampaignType.DRAFT;
      },
      rootState,
    ) {
      const { data, error } = await fetchCampaignsAPI({
        type: payload.type,
        limit: payload.limit || 10,
        offset: payload.offset,
        start_time: new Date(0),
        end_time: new Date(),
      });

      if (error) {
        this.setState({
          [payload.type]: {
            isFetching: false,
            campaigns: [],
            totalCount: 0,
          },
        });
      } else {
        const prevState = rootState.campaigns[payload.type];
        const previousOffset = payload.offset + prevState.limit;
        const hasMore = previousOffset < data.summary.count_overall;
        this.setState({
          [payload.type]: {
            ...prevState,
            isFetching: false,
            previousOffset,
            hasMore,
            campaigns:
              payload.offset === 0
                ? data.campaigns
                : [...prevState.campaigns, ...data.campaigns],
            totalCount: data.summary.count_overall,
          },
        });
      }
    },

    async fetchDraftCampaigns(payload: { offset?: number } = {}, rootState) {
      const offset =
        payload.offset !== undefined
          ? payload.offset
          : rootState.campaigns.draft.previousOffset;
      this.fetchCampaigns({
        type: CampaignType.DRAFT,
        offset,
      });
    },

    async delete({
      id,
      type,
    }: {
      id: number;
      type: Exclude<CampaignType, CampaignType.SENT>;
    }) {
      const t = await getT(Router.locale, 'toasts');

      const { error } = await deleteCampaignAPI(id);

      if (error) {
        dispatch.saveToast.showError(t('Error deleting campaign'));
        return;
      }

      if (type === CampaignType.PAUSED) {
        this.fetchPausedCampaigns();
        this.fetchTotalStats([CampaignType.PAUSED]);
      } else if (type === CampaignType.SCHEDULED) {
        this.fetchScheduledCampaigns();
        this.fetchTotalStats([CampaignType.SCHEDULED]);
      } else
        this.fetchCampaigns({
          type,
          offset: 0,
        });

      dispatch.saveToast.showDone(t('Campaign successfully deleted'));
    },

    async resend(payload: number) {
      const t = await getT(Router.locale, 'toasts');

      const { error } = await resendCampaignAPI(payload);

      if (error) dispatch.saveToast.showError(t('Error resending campaign'));

      this.fetchTotalStats([CampaignType.PAUSED]);
      this.fetchPausedCampaigns();
      dispatch.saveToast.showDone(t('Campaign successfully resent'));
    },
  }),

  reducers: {
    setSentState(state, payload: Partial<CampaignsModelState['sent']>) {
      return {
        ...state,
        sent: {
          ...state.sent,
          ...payload,
        },
      };
    },

    setScheduledState(
      state,
      payload: Partial<CampaignsModelState['scheduled']>,
    ) {
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          ...payload,
        },
      };
    },

    setPausedState(state, payload: Partial<CampaignsModelState['paused']>) {
      return {
        ...state,
        paused: {
          ...state.paused,
          ...payload,
        },
      };
    },

    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },

    setReports(state, payload: Partial<CampaignsModelState['reports']>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    resetSentRecordsPerPage(state) {
      return {
        ...state,
        sent: {
          ...state.sent,
          recordsPerPage: 10,
        },
      };
    },
  },
});

export default campaigns;
