export const identify = (identifier: string, traits: AnyObject) => {
  if (typeof window?.analytics?.identify === 'function')
    window.analytics.identify(identifier, traits);
};

export const trackPage = () => {
  if (typeof window?.analytics?.page === 'function') window.analytics.page();
};

export const simpleTrack = (identifier: string) => {
  if (typeof window?.analytics?.track === 'function')
    window.analytics.track(identifier);
};
