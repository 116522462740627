import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models';

type Onboarding_preference = 'webpush' | 'email' | 'neither';

type IntegratedDashboardState = {
  isLoading: boolean;
  onboarding_preference: Onboarding_preference;
  skippedOnboarding: Onboarding_preference;
};

const initialState: IntegratedDashboardState = {
  isLoading: false,
  onboarding_preference: 'neither',
  skippedOnboarding: 'neither',
};

const integratedDashboard = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async syncPreferences(_, rootState) {
      const {
        integratedOnboarding: {
          onboarding: { onboarding_setup_choice },
        },
      } = rootState;

      dispatch.integratedDashboard.setOnboardingState({
        isLoading: true,
      });

      await dispatch.integratedOnboarding.fetchMerchantPreferences().then(() =>
        dispatch.integratedDashboard.setOnboardingState({
          onboarding_preference: onboarding_setup_choice,
          isLoading: false,
        }),
      );
    },
  }),

  reducers: {
    setOnboardingState(state, payload: Partial<IntegratedDashboardState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default integratedDashboard;
