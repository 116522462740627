import { createModel } from '@rematch/core';
import {
  AllTimeTimeFilter,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import { OldTimeFilter } from 'src/components/ReportSaver/models';
import { fetchBAOrderDataAPI, fetchBAReportsAPI } from 'src/lib/api/reports';
import {
  fetchBAConfigAPI,
  setBAConfigAPI,
} from 'src/lib/api/automation/browse-abandonment';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { ACR_BA_Report, AutomationConfig, OrderData } from './types';

interface BrowseAbandonmentState {
  isFetching: boolean;
  config: {
    enabled: boolean;
    notification_configs: Array<AutomationConfig>;
  };
  notificationInEdit: number;
  timeFilter: TimeFilter;
  reports: ACR_BA_Report;
  orderData: OrderData;
}

const initialState = (): BrowseAbandonmentState => ({
  isFetching: false,
  config: null,
  notificationInEdit: null,
  timeFilter: AllTimeTimeFilter,
  reports: {
    isFetching: true,
    total: {
      impressions: 0,
      clicks: 0,
      revenue: 0,
      cartsRecovered: 0,
    },
    reminders: [],
  },
  orderData: {
    recordsPerPage: 10,
    totalCount: 0,
    hasMore: false,
    orders: [],
  },
});

const browseAbandonmentAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async fetchReports(
      // TODO:aftermigration - use the new time filter type only
      { timeFilter }: { timeFilter: TimeFilter | OldTimeFilter },
      rootState,
    ) {
      const t = await getT(Router.locale, 'toasts');

      this.storeState({ timeFilter });
      this.storeReports({ isFetching: true });

      const { reports, orderData, error } = await fetchBAReportsAPI(
        timeFilter,
        rootState.settings.attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError(t('Error fetching reports'));
        return;
      }

      this.storeReports({ isFetching: false, ...reports });
      this.storeState({ orderData });
    },

    async fetchOrderData(_, rootState) {
      const t = await getT(Router.locale, 'toasts');

      const {
        settings: { attributionWindow },
        browseAbandonmentAutomation: {
          timeFilter,
          orderData: { recordsPerPage },
        },
      } = rootState;

      const { orderData, error } = await fetchBAOrderDataAPI(
        { timeFilter, recordsPerPage },
        attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError(t('Error fetching orders'));
        return;
      }

      this.storeState({ orderData });
    },

    async fetchConfig() {
      this.storeState({ isFetching: true });

      const { data, error } = await fetchBAConfigAPI();

      if (!error) {
        this.storeState({ config: data, isFetching: false });
      }
    },

    async toggle(payload: boolean, rootState) {
      const t = await getT(Router.locale, 'toasts');

      const state = rootState.browseAbandonmentAutomation;

      let notification_configs = state.config?.notification_configs || [];

      notification_configs = notification_configs.map(item => ({
        ...item,
        enabled: payload,
      }));

      const newState = {
        ...state,
        config: {
          ...state.config,
          notification_configs,
          enabled: payload,
        },
      };

      this.storeState(newState);

      // make network request
      await setBAConfigAPI(newState.config).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone(t('Changes saved'));
        } else {
          store.dispatch.saveToast.showError(t('Error saving changes'));
          this.storeState(state);
        }
      });
    },

    async saveNotification(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const t = await getT(Router.locale, 'toasts');

      const { _id, ...restPayload } = payload;
      const state = rootState.browseAbandonmentAutomation;
      const notificationIndex = Number.isInteger(state.notificationInEdit)
        ? state.notificationInEdit
        : _id;
      const { notification_configs } = state.config;
      const notification = {
        ...notification_configs[notificationIndex],
        ...restPayload,
      };

      notification_configs[notificationIndex] = notification;

      const newState = {
        ...state,
        config: {
          ...state.config,
          notification_configs,
        },
        notificationInEdit: null,
      };

      // make network request
      return setBAConfigAPI(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone(t('Changes saved'));
        } else {
          store.dispatch.saveToast.showError(t('Error saving changes'));
        }

        return res;
      });
    },
  },

  reducers: {
    storeState(state, payload: Partial<BrowseAbandonmentState>) {
      return {
        ...state,
        ...payload,
      };
    },

    storeReports(state, payload: Partial<BrowseAbandonmentState['reports']>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    initNotificationEditing(state, payload: number) {
      return {
        ...state,
        notificationInEdit: payload,
      };
    },

    shutNotificationEditing(state) {
      return {
        ...state,
        notificationInEdit: null,
      };
    },

    resetTimeFilter(state) {
      return {
        ...state,
        timeFilter: initialState().timeFilter,
      };
    },
  },
});

export default browseAbandonmentAutomation;
