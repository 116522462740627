import { subDays } from 'date-fns';
import { get, post } from './axios';

export const fetchSubscriberReportAPI = () => {
  const endDate = new Date();
  const startDate = subDays(endDate, 30);

  // cover the whole day by setting time to 00:00:00
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  return get(
    `/dashboard/subscriber/report/?page=home&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
  );
};

export const fetchSummaryAPI = () => {
  const endDate = new Date();
  const startDate = subDays(endDate, 30);

  // cover the whole day by setting time to 00:00:00
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  return get(
    `/dashboard/home/summary/?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
  );
};

export const fetchThemeAppExtensionStatusAPI = () =>
  get(`/dashboard/steps-card/`);

export const toggleNewDashboardAPI = () => post(`/dashboard/toggle-bfs/`, null);
