import { EmailOnboardingStep } from 'src/modules/email-onboarding/models/constants';
import { ReservedElementNodeId } from 'src/modules/optins/components/FormWidget/lib/constants';

export enum ChannelType {
  'WEBPUSH' = 'webpush',
  'SMS' = 'sms',
  'EMAIL' = 'email',
}

export enum Status {
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
  'DRAFT' = 'draft',
}

export enum FlowNodeType {
  'WAIT_TIME' = 'wait_time',
  'FORM_COLLECTION' = 'form_collection',
}

export enum OptinType {
  POPUP = 'popup',
  CUSTOM_WEBPUSH = 'custom_webpush',
  BROWSER_WEBPUSH = 'browser_webpush',
}

export enum FormWidgetType {
  STEP_1 = 'step_1',
  STEP_2 = 'step_2',
  TEASER = 'teaser',
}

export enum FormWidgetPosition {
  CENTERED = 'centered',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTERED = 'bottom_centered',
  BOTTOM_RIGHT = 'bottom_right',
  TOP_LEFT = 'top_left',
  TOP_CENTERED = 'top_centered',
  TOP_RIGHT = 'top_right',
}

// general constants
export const ChannelDisplayName: Record<ChannelType, string> = {
  [ChannelType.EMAIL]: 'Email',
  [ChannelType.SMS]: 'SMS',
  [ChannelType.WEBPUSH]: 'Web Push',
};

export const OptinTypeDisplayName: Record<OptinType, string> = {
  [OptinType.POPUP]: 'Popup',
  [OptinType.CUSTOM_WEBPUSH]: 'Custom Prompt',
  [OptinType.BROWSER_WEBPUSH]: 'Browser Prompt',
};

export const FormWidgetTypeDisplayName: Record<FormWidgetType, string> = {
  [FormWidgetType.STEP_1]: 'Step 1',
  [FormWidgetType.STEP_2]: 'Step 2',
  [FormWidgetType.TEASER]: 'Teaser',
};

export const StatusDisplayName: Record<Status, string> = {
  [Status.ACTIVE]: 'Active',
  [Status.INACTIVE]: 'Inactive',
  [Status.DRAFT]: 'Draft',
};

export const ReservedTextNodeCharacterLimits: Record<string, number> = {
  [ReservedElementNodeId.TITLE]: 50,
  [ReservedElementNodeId.SUBTITLE]: 100,
  [ReservedElementNodeId.TEASER]: 45,
};

export const OptinFlowPreviewTooltipContent = {
  [OptinType.BROWSER_WEBPUSH]: 'Single step optin',
  [OptinType.CUSTOM_WEBPUSH]: '2 step optin',
};

export const PositioningDisplayName: Record<FormWidgetPosition, string> = {
  [FormWidgetPosition.BOTTOM_LEFT]: 'Bottom Left',
  [FormWidgetPosition.BOTTOM_RIGHT]: 'Bottom Right',
  [FormWidgetPosition.BOTTOM_CENTERED]: 'Bottom Centered',
  [FormWidgetPosition.CENTERED]: 'Centered',
  [FormWidgetPosition.TOP_CENTERED]: 'Top Centered',
  [FormWidgetPosition.TOP_LEFT]: 'Top Left',
  [FormWidgetPosition.TOP_RIGHT]: 'Top Right',
};

export const WebPushMobilePositioningDisplayName: Record<string, string> = {
  [FormWidgetPosition.BOTTOM_CENTERED]: 'Bottom',
  [FormWidgetPosition.TOP_CENTERED]: 'Top',
};

export enum MerchantTask {
  DATA_SYNC_SHOPIFY_TO_BREVO = 'data_sync_shopify_to_brevo',
}

export const IntercomTriggerMessage = {
  [EmailOnboardingStep.ABOUT_1]:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  [EmailOnboardingStep.ABOUT_2]:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  [EmailOnboardingStep.SYNC_DATA]:
    'Hi, I have some questions on the data sync process.',
  SYNC_DATA_FAILED:
    'Hi, The data sync process has failed, can you help me out?',
  [EmailOnboardingStep.SETUP_SENDER]:
    'Hi, I need help with sender verification while setting up email.',
  DEFAULT_EMAIL:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  MERCHANT_IN_ANNUAL_PLAN:
    'Hi, I want to get started with PushOwl Email and would like to talk about a suitable plan that serves my needs.',
  PRICING_CHAT_WITH_US:
    'Hi, I need help with determining the best plan according to my needs.',
  DKIM_2K_RECORD: 'Hi, I need help with setting up a 2048-bit DKIM key',
};

export const EmailHelpDocumentation = {
  [EmailOnboardingStep.ABOUT_1]: 'https://pushowl.com/email',
  [EmailOnboardingStep.ABOUT_2]: 'https://pushowl.com/email',
  [EmailOnboardingStep.SYNC_DATA]:
    'https://docs.pushowl.com/en/articles/9024515-sync-your-data-from-shopify',
  [EmailOnboardingStep.SETUP_SENDER]:
    'https://docs.pushowl.com/en/articles/9024517-creating-a-sender',
  [EmailOnboardingStep.PRICING]: 'https://pushowl.com/email',
  HOME_PAGE: 'https://docs.pushowl.com',
};
