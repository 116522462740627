import { getStorage } from 'src/lib/storage';
import { useEffect } from 'react';

export const AppBridgeProvider = ({ children }) => {
  useEffect(() => {
    // Using plain js search param since Next js query param is available after a delay
    const queryParams = new URLSearchParams(window.location.search);
    const stepReference = queryParams.get('step_reference');

    // Store step_reference in storage if it is present for shopify marketing automation
    // TODO: Move this to Redux
    const storage = getStorage();
    storage.set('step_reference', stepReference);
  }, []);

  return children;
};
